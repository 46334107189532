#namer {
    margin-bottom: 16px;
}

#time { 
    width:100%;
    border:1px solid black;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
    
    border: 4;
    border-radius: 4px;
    padding: 8px;
    margin-bottom: 0px;
    -moz-appearance: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    appearance: none;

    font-family: "Roboto", sans-serif;
    font-size: 16pt;
    font-weight: 400;
    letter-spacing: -0.01em;
    line-height: 1.65em; 
    color:darkslategrey;
}

.blue {
    background-color: hsl(225, 73%, 57%);   
}

.blue:hover {
    background-color: hsl(225, 73%, 62%);
}

.blue:active {
    background-color: hsl(225, 73%, 52%);
}

.red {
    background-color: hsl(0, 53%, 58%);
}

.red:hover {
    background-color: hsl(0, 53%, 63%);
}

.red:active {
    background-color: hsl(0, 53%, 53%);
}

.form-control{
    width:100%  !important;
    height:100% !important;
    border:1px solid black;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
    
    border: 4  !important;
    border-radius: 4px  !important;
    padding: 8px;
    margin-top: 0px;
    -moz-appearance: none  !important;
    -webkit-appearance: none  !important;
    -ms-appearance: none  !important;
    appearance: none  !important;

    font-family: "Roboto", sans-serif;
    font-size: 16pt  !important;
    font-weight: 400  !important;
    letter-spacing: -0.01em  !important;
    line-height: 1.65em  !important;
    color:darkslategrey  !important;
}


.react-tel-input {
    margin-top: 16px !important;
    margin-bottom: 0px !important;
    margin-left: 0;
}


.profile-btn {
    background:none;
    border:none;
    margin-right: 0.5em;
    padding:0;
    cursor: pointer;
    width: 64px;
    height: 64px;
}


.profile-btn:hover {
    background: none;
    border:none;
    box-shadow:none;
}

.profile-btn:active {
    background: none;
    border:none;
    box-shadow:none;
}

.profile-btn img {
    border-radius: 48px;
    -moz-border-radius: 48px;
    -khtml-border-radius: 48px;
    -webkit-border-radius: 48px;
    width: 64px;
    height: 64px;
}


.profile-btn img:hover {
    border-radius: 48px;
    -moz-border-radius: 48px;
    -khtml-border-radius: 48px;
    -webkit-border-radius: 48px;
    width: 64px;
    height: 64px;
}


.profile-crown {
    position: relative;
    top: -100px;
    width: 32px    !important;
    height: 32px   !important;
}


#dp-img:hover {
    border: 4px solid red;
    transition: 0.0s;
}


#bc-img:hover {
    border: 4px solid coral;
    transition: 0.0s;
}


#ac-img:hover {
    border: 4px solid royalblue;
    transition: 0.0s;
}


#aw-img:hover {
    border: 4px solid darkgrey;
    transition: 0.0s;
}